<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.daily_sales_report') }}</h4>
        </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
            <b-row>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Fertilizer" vid="fertilizer_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="fertilizer_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('movement.fertilizerName') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.fertilizer_id"
                      :options="fertilizerNameList"
                      id="fertilizer_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="12">
                <ValidationProvider name="Godown" vid="godown_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="godown_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('fertilizerSales.godown') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.godown_id"
                      :options="godownInfoList"
                      id="godown_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
             <b-row>
              <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="From Date" vid="from_date" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="from_date"
                      slot-scope="{ valid, errors }"
                  >
                      <template v-slot:label>
                          {{$t('globalTrans.from_date')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input class="form-control"
                          v-model="search.from_date"
                          placeholder="Select Date"
                          id="from_date"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                     <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                <ValidationProvider name="To Date" vid="to_date" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="to_date"
                       slot-scope="{ valid, errors }"
                  >
                      <template v-slot:label>
                          {{$t('globalTrans.to_date')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input class="form-control"
                          v-model="search.to_date"
                          placeholder="Select Date"
                          id="to_date"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                     <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="12">
                  <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
           </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
      <b-row >
        <b-col md="12" v-if="showData">
          <b-overlay :show='loading'>
            <iq-card v-if='sales.length'>
              <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('fertilizerReport.daily_sales_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                          <!-- <export-excel
                            class="btn btn-success mr-2"
                            :data="dataCustomize"
                            :fields= "json_fields"
                            :title="$t('fertilizerReport.received_report')"
                            worksheet="Product Stock Report Sheet"
                            name="received_report.xls">
                            {{ $t('globalTrans.export_excel') }}
                          </export-excel> -->
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <b-row>
                                  <b-col>
                                    <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                                      {{ $t('fertilizerReport.daily_sales_report') }}
                                    </list-report-head>
                                  </b-col>
                                </b-row>
                                <b-table-simple class="tg" hover borderless small caption-top>
                                  <b-thead>
                                    <b-tr>
                                      <b-td class="tg-0lax">{{$t('fertilizerSales.godown')}}</b-td>
                                      <b-td class="text-center">:</b-td>
                                      <b-td class="tg-0lax">{{ getDataById(search.godown_id, 'godown') }}</b-td>
                                      <b-td class="tg-0lax">{{$t('globalTrans.from_date')}} </b-td>
                                      <b-td class="text-center">:</b-td>
                                      <b-td class="tg-0lax">{{ search.from_date | dateFormat}}</b-td>
                                      <b-td class="tg-0pky">{{$t('globalTrans.to_date') }}</b-td>
                                      <b-td class="text-center">:</b-td>
                                      <b-td class="tg-0lax">{{ search.to_date | dateFormat}}</b-td>
                                      <b-td class="tg-0lax">{{$t('movement.fertilizerName') }}</b-td>
                                      <b-td class="text-center">:</b-td>
                                      <b-td class="tg-0lax">{{ getDataById(search.fertilizer_id, 'fer') }}</b-td>
                                    </b-tr>
                                  </b-thead>
                                </b-table-simple>
                                <b-table-simple bordered style="font-size:12px;color:#555555;">
                                  <b-thead head-variant="secondary">
                                      <b-tr>
                                          <b-th rowspan="2" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                          <b-th rowspan="2" class="text-center">{{ $t('fertilizerSales.sale_date') }}</b-th>
                                          <b-th rowspan="2" class="text-center">{{ $t('fertilizerSales.memo_no') }}</b-th>
                                          <b-th rowspan="2" class="text-center">{{$t('fertilizerReport.dealer_org')}}</b-th>
                                          <b-th rowspan="2" class="text-center">{{ $t('fertilizerSales.dealer_type') }}</b-th>
                                          <b-th :colspan="3" class="text-center">{{ $t('fertilizerReport.amount_of_purchase') }}</b-th>
                                          <b-th :colspan="3" class="text-center">{{ $t('fertilizerReport.price_of_sold') }}</b-th>
                                          <b-th :colspan="4" class="text-center">{{ $t('fertilizerReport.money_recieve_info') }}</b-th>
                                          <b-th rowspan="2" class="text-center">{{$t('fertilizerSales.comment')}}</b-th>
                                      </b-tr>
                                      <b-tr>
                                          <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'first_' + index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-th>
                                          <b-th class="text-right" v-for="(item, index) in fertilizerNameList" :key="'second_' + index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-th>
                                          <b-th class="text-center">{{ $t('fertilizerSales.pay_order_no') }}</b-th>
                                          <b-th class="text-center">{{ $t('globalTrans.date') }}</b-th>
                                          <b-th class="text-center">{{ $t('fertilizerReport.bank_branch') }}</b-th>
                                          <b-th class="text-center">{{ $t('fertilizerReport.amount_of_money') }}</b-th>
                                      </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <b-tr  v-for="(report, index) in sales" :key="index">
                                      <b-td class="tg-0lax text-center">{{$n(index+1)}}</b-td>
                                      <b-td class="tg-0lax text-center">{{ report.sell_date | dateFormat }}</b-td>
                                      <b-td class="tg-0lax text-center">
                                        {{ $n(report.memo_no, { useGrouping: false }) }}
                                      </b-td>
                                      <b-td class="tg-0lax text-center">
                                        {{ $i18n.locale === 'bn' ?  report.dealer.name_bn : report.dealer.name }}
                                        <br>{{ $i18n.locale === 'bn' ? report.dealer.dealer_details.per_address_bn : report.dealer.dealer_details.per_address }}
                                      </b-td>
                                      <b-td class="tg-0lax text-center">{{ report.dealer.category === 2 ? $t('fertilizerSales.fertilizer') : $t('fertilizerSales.seeds') }}</b-td>
                                      <b-td class="tg-0lax text-center" v-for="(item, index1) in fertilizerNameList" :key="'5' + index + index1">{{ item.value === report.fertilizer_id ? $n(report.quantity, { useGrouping: false }) : null }}</b-td>
                                      <b-td class="tg-0lax text-center" v-for="(item, index2) in fertilizerNameList" :key="'6' + index + index2">{{ item.value === report.fertilizer_id ? $n(report.total_price, { useGrouping: false }) : null }}</b-td>
                                      <b-td class="tg-0lax text-right"><div v-for="(pay, index3) in report.pay_orders" :key="'1' + index + index3"> {{ $n(pay.pay_order_no, { useGrouping: false }) }}<br></div></b-td>
                                      <b-td class="tg-0lax text-right"><div v-for="(pay, index4) in report.pay_orders" :key="'2' + index + index4">{{ pay.pay_order_date | dateFormat }}{{ ',' }}<br></div></b-td>
                                      <b-td class="tg-0lax text-center"><div v-for="(pay, index5) in report.pay_orders" :key="'3' + index + index5">{{ getDataById(pay.bank_id, 'bank') }} {{ '-' }}{{ getDataById(pay.branch_id, 'branch') }}{{ ',' }}<br></div></b-td>
                                      <b-td class="tg-0lax text-center"><div v-for="(pay, index6) in report.pay_orders" :key="'4' + index + index6">{{ $n(pay.amount, { useGrouping: false }) }}{{ ',' }}<br></div></b-td>
                                      <b-td class="tg-0lax text-center">{{ $i18n.locale === 'bn' ? report.comments_bn : report.comments}}</b-td>
                                    </b-tr>
                                  </b-tbody>
                                </b-table-simple>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
               </template>
            </iq-card>
            <div class="panel-body text-center mt-3"  v-else>
              <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
  </b-container>
</template>
<style type="text/css">
.tg  {border-collapse:collapse;border-spacing:0;}
.tg td{text-align: center !important; vertical-align: middle !important;}
.tg th{text-align: center !important; vertical-align: middle !important;}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, dailySalesReport } from '../../api/routes'
import ListReportHead from '.././ListReportHead.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdf from './export-pdf_details'
import excel from 'vue-excel-export'
import flatpickr from 'flatpickr'
import Vue from 'vue'
Vue.use(excel)

const excelColumn = {
  Serial: 'serial',
  'Receive Date': 'receive_date',
  'Lori Challan No': 'lori_challan_no',
  'Delivered Amount': 'delivered_amount'
}
const excelColumnBn = {
  'কর্িমক নং': 'serial',
  'পর্াপ্ত তািরখ': 'receive_date',
  'লির চালান নং': 'lori_challan_no',
  'পর্াপ্ত পিরমাণ': 'delivered_amount'
}
export default {
  name: 'UiDataTable',
  components: {
    ListReportHead,
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      showData: false,
      search: {
        godown_id: 0,
        fertilizer_id: 0,
        from_date: '',
        to_date: ''
      },
      sales: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      loading: false
    }
  },
  computed: {
    json_fields: function () {
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
    },
    godownInfoList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.godownInfoList.filter(item => item.status === 1)
    }
  },
  mounted () {
    flatpickr('#from_date', {})
    flatpickr('#to_date', {})
    core.index()
  },
  methods: {
    calculateTotal (report) {
       return report.map(r => r.delivered_amount).reduce((a, c) => {
        a = a ? parseInt(a) : 0
        c = c ? parseInt(c) : 0
        return a + c
      })
    },
    async register () {
      this.showData = true
      this.loadData()
    },
    async loadData () {
      this.loading = true
      await RestApi.getData(seedFertilizerServiceBaseUrl, dailySalesReport, this.search).then(response => {
        if (response.success) {
          if (response.data) {
            this.sales = response.data
            this.showData = true
          }
        } else {
          this.sales = []
        }
        this.loading = false
      })
    },
    pdfExport () {
      const data = {
        godown: this.getDataById(this.search.godown_id, 'godown'),
        fertilizer: this.getDataById(this.search.fertilizer_id, 'fer')
      }
      const temps = Object.assign(this.search, data)
      this.search = temps
      const bankList = this.$store.state.commonObj.bankObj.bankList
      const branchList = this.$store.state.commonObj.bankObj.branchList
      const reportTitle = this.$t('fertilizerReport.daily_sales_report')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.sales, this, this.fertilizerNameList, this.search, bankList, branchList)
    },
    getDataById (Id, type) {
      var data = ''
      if (type === 'org') {
        data = this.$store.state.commonObj.orgList.find(item => item.value === Id)
      }
      if (type === 'upz') {
        data = this.$store.state.commonObj.upazilaList.find(item => item.value === Id)
      }
      if (type === 'godown') {
        data = this.godownInfoList.find(item => item.value === Id)
      }
      if (type === 'fer') {
        data = this.fertilizerNameList.find(item => item.value === Id)
      }
      if (type === 'bank') {
        data = this.$store.state.commonObj.bankObj.bankList.find(item => item.value === Id)
      }
      if (type === 'branch') {
        data = this.$store.state.commonObj.bankObj.branchList.find(item => item.value === Id)
      }

      if (data) {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return null
      }
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
