import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

function getDataById (dataField, Id, vm) {
  const data = dataField.find(item => item.value === Id)
  if (data) {
    return vm.$i18n.locale === 'bn' ? data.text_bn : data.text_en
  } else {
    return null
  }
}
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, sales, vm, fertilizer, search, bankList, branchList) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'header3', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['9%', '3%', '13%', '9%', '3%', '13%', '9%', '3%', '13%', '11%', '4%', '11%'],
              body: [
                [
                  { text: vm.$t('fertilizerSales.godown'), style: 'th' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: search.godown, style: 'td' },
                  { text: vm.$t('globalTrans.from_date'), style: 'th' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: dateFormat(search.from_date), style: 'td' },
                  { text: vm.$t('globalTrans.to_date'), style: 'th' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: dateFormat(search.to_date), style: 'td' },
                  { text: vm.$t('movement.fertilizerName'), style: 'th' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: search.fertilizer, style: 'td' }
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        pdfContent.push({ text: '', style: 'fertilizer', alignment: 'center' })
        var allRow1 = []
        var tabledata = [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', rowSpan: '2', alignment: 'center' },
            { text: vm.$t('fertilizerSales.sale_date'), style: 'th', rowSpan: '2', alignment: 'center' },
            { text: vm.$t('fertilizerSales.memo_no'), style: 'th', rowSpan: '2', alignment: 'center' },
            { text: vm.$t('fertilizerReport.dealer_org'), style: 'th', rowSpan: '2', alignment: 'center' },
            { text: vm.$t('fertilizerSales.dealer_type'), style: 'th', rowSpan: '2', alignment: 'center' },
            { text: vm.$t('fertilizerReport.amount_of_purchase'), style: 'th', colSpan: '3', alignment: 'center' },
            { },
            { },
            { text: vm.$t('fertilizerReport.price_of_sold'), style: 'th', colSpan: '3', alignment: 'center' },
            { },
            { },
            { text: vm.$t('fertilizerReport.money_recieve_info'), style: 'th', colSpan: '4', alignment: 'center' },
            { },
            { },
            { },
            { text: vm.$t('fertilizerSales.comment'), style: 'th', rowSpan: '2', alignment: 'center' }
          ]
        allRow1.push(tabledata)
       tabledata = [
          { },
          { },
          { },
          { },
          { }
        ]
        fertilizer.map(item => {
          const txt = { text: vm.$i18n.locale === 'en' ? item.text_en : item.text_bn, style: 'th', alignment: 'center' }
          tabledata.push(txt)
        })
        fertilizer.map(item => {
          const txt = { text: vm.$i18n.locale === 'en' ? item.text_en : item.text_bn, style: 'th', alignment: 'center' }
          tabledata.push(txt)
        })
        const temp = [
          { text: vm.$t('fertilizerSales.pay_order_no'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.date'), style: 'th', alignment: 'center' },
          { text: vm.$t('fertilizerReport.bank_branch'), style: 'th', alignment: 'center' },
          { text: vm.$t('fertilizerReport.amount_of_money'), style: 'th', alignment: 'center' }
        ]
        var tmp = tabledata.concat(temp)
        tmp.push({})
        allRow1.push(tmp)
        sales.forEach((report, index) => {
          tabledata = [
          { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
          { text: dateFormat(report.sell_date), style: 'td', alignment: 'center' },
          { text: vm.$n(report.memo_no), style: 'td', alignment: 'center' },
          { text: vm.$i18n.locale === 'bn' ? (report.dealer.name_bn + '-' + report.dealer.dealer_details.per_address_bn) : (report.dealer.name + '-' + report.dealer.dealer_details.per_address), style: 'td', alignment: 'center' },
          { text: report.dealer.category === 2 ? vm.$t('fertilizerSales.fertilizer') : vm.$t('fertilizerSales.seeds'), style: 'td', alignment: 'center' }
          ]
          var txt = ''
          fertilizer.map(item => {
            if (item.value === report.fertilizer_id) {
              txt = { text: vm.$n(report.quantity, { useGrouping: false }), style: 'th', alignment: 'center' }
            } else {
              txt = null
            }
            tabledata.push(txt)
          })
          fertilizer.map(item => {
            if (item.value === report.fertilizer_id) {
              txt = { text: vm.$n(report.total_price, { useGrouping: false }), style: 'th', alignment: 'center' }
            } else {
              txt = null
            }
            tabledata.push(txt)
          })
          var text = ''
          report.pay_orders.map(item => {
            text = text + vm.$n(item.pay_order_no.toString(), { useGrouping: false })
            text = text + ', '
          })
          text = { text: text, style: 'th', alignment: 'center' }
          tabledata.push(text)
          text = ''
          report.pay_orders.map(item => {
            text = text + dateFormat(item.pay_order_date.toString())
            text = text + ', '
          })
          text = { text: text, style: 'th', alignment: 'center' }
          tabledata.push(text)
          let payOrders = ''
          report.pay_orders.map(item => {
            payOrders = getDataById(bankList, item.bank_id, vm) + ', ' + getDataById(branchList, item.branch_id, vm)
          })
          text = { text: payOrders, style: 'th', alignment: 'center' }
          tabledata.push(text)
          text = ''
          report.pay_orders.map(item => {
            text = text + vm.$n(item.amount.toString(), { useGrouping: false })
            text = text + ', '
          })
          text = { text: text, style: 'th', alignment: 'center' }
          tabledata.push(text)
          tabledata.push({ text: vm.$i18n.locale === 'bn' ? report.comments_bn : report.comments, style: 'th', alignment: 'center' })
          allRow1.push(tabledata)
        })
        pdfContent.push(
          {
            table: {
                headerRows: 0,
                margin: 0,
                style: 'header2',
                widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: allRow1
            }
          }
        )
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Landscape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            border: 0,
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          fertilizer: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
          fontSize: 9,
          margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('daily-sales-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
